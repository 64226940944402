



















































import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { AuthForm } from "@/data/auth";
import ModalFooter from "@/components/common/modalfooter.vue";
@Component({
  components: { ModalFooter }
})
export default class LoginComponent extends Vue {
  loading: boolean = false;
  @Inject() $validator: any;
  @Prop() form: AuthForm;

  async submit(): Promise<void> {
    this.$validator.validate("form.*").then(async (res: boolean) => {
      if (res) {
        try {
          this.loading = true;
          await this.form.submit().then(nextRoute => {
            this.$router.push({ name: nextRoute });
          });
        } finally {
          this.loading = false;
        }
      }
    });
  }
}
